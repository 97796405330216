// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

a{
   text-decoration: none;
}

html, body, #app{
   height: 100%;
}

@media screen and (max-width: 600px) {
   .table-mobile-break{
      display: block;
   }
   
   .table-mobile-break thead{
      display: none;
   }
   .table-mobile-break tbody{
      display: block;
   }
   
   .table-mobile-break tbody tr{
      display: block;
      margin-bottom: .5rem;
      border-bottom: 1px solid #dee2e6;
   }
   
   .table-mobile-break tbody td{
      display: block;
      border-bottom: none;
      width: 100%;
   }

   .table-mobile-break tbody td:last-child{
      display: none;
   }

   .bg-mobile{
      background: #f9f9fa !important;
   }

}

.cursor-pointer{
   cursor: pointer;
}