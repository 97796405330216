// Body
$body-bg: #f8fafc;
$light: #e9ecef;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;